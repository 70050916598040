import {Component} from '@angular/core'
import {LinkService} from '../../../../../core/i18n/link.service'
import {PUBLIC_ROUTES} from '../../../../../shared/routing/public.routes'
import {Monologic} from '../../../../../craft/dialog/monologic.interface'
import {DialogRef} from '@angular/cdk/dialog'

@Component({
  selector: 'bgo-happiness-dialog',
  templateUrl: './happiness-dialog.component.html',
})
export class HappinessDialogComponent implements Monologic<Props> {
  text!: string
  APPLICATION_ROUTES = PUBLIC_ROUTES

  constructor(
    readonly linkService: LinkService,
    private readonly dialogRef: DialogRef,
  ) {}

  setData(data: Props): void {
    this.text = data.text
  }

  close() {
    this.dialogRef.close()
  }
}

interface Props {
  text: string
}
